
.CarLine {
  width:calc(100% - 2vw);
  margin:0 0.5vw;
  padding:0.5vw;
  border-bottom:1px dashed #999;
  &:hover {background-color: #1115;}
  @media (min-width: 481px) and (max-width: 767px) {
    width:calc(100% - 3vw);
    padding:1vw;
  }
  @media (max-width: 481px){
    width:calc(100% - 3vw);
  }

  .Cell {
    padding:0.5vw;
    @media (max-width: 481px){
      padding:0.5vw 1.5vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .BrandModel {
    width:15%;
    @media (min-width: 481px) and (max-width: 767px) {
      width:25%;
      font-size:130%;
    }
    @media (max-width: 481px){
      flex:1;
      font-size:130%;
    }
  }
  .MethodSM {
    display:none;
    @media (max-width: 481px){
      display:flex;
      width:30%;
    }
  }
  .Lines {
    width:77%;
    @media (min-width: 481px) and (max-width: 767px) { width:60%; }
    @media (max-width: 481px){ width:100%; }

    .ECU {
      width:calc(100% - 1vw);
      padding:0 0.5vw;
      border-bottom:1px dashed #555;
      @media (max-width: 767px) { border:none; }
  
      .ECUType { flex:1; }
      .HWv {
        width:30%;
        @media (max-width: 481px){ width:25%; }
      }
      .SWv {
        width:30%;
        @media (max-width: 481px){ width:25%; }
      }
    }
    .Prog {
      width:calc(100% - 1vw);
      padding:0 0.5vw;
      @media (max-width: 767px){ display:none; }
  
      .Programmer { flex:1; }
      .Mod {
        width:30%;
        @media (max-width: 481px){ width:30%; }
      }
      .Type {
        width:30%;
        @media (max-width: 481px){ width:30%; }
      }
    }
  }
  .Method {
    flex:1;
    @media (max-width: 481px){
      display:none;
    }
  }
}

.CarLineTop {
  background-color: #111c;
  &:hover {background-color: #111e;}
}