
.BzTune {
  position:relative;
  width:100%;
  flex:1;

  .SoftInfo {
    width:calc(100% - 2vw);
    margin:1vw 0;
    background-color: #111;
    border-radius:0.5vw;
    box-shadow: 0 0 0.5vw 0.5vw #999;
    @media (min-width: 481px) and (max-width: 767px) {
      width:calc(100% - 4vw);
      border-radius:1vw;
      box-shadow: 0 0 1vw 1vw #999;
    }
    @media (max-width: 481px) {
      width:calc(100% - 4vw);
      border-radius:1vw;
      box-shadow: 0 0 1vw 1vw #999;
    }

    .InfoArea {
      width:calc(100% - 4vw - 2px);
      margin:1vw;
      padding:0 1vw;
      border-radius:0.5vw;
      border:1px dashed #f60;
      @media (min-width: 481px) and (max-width: 767px) {
        width:calc(100% - 5vw - 2px);
        margin:1.5vw;
        padding:0 1vw;
        border-radius:1vw;
      }
      @media (max-width: 481px) {
        width:calc(100% - 8vw - 2px);
        margin:2vw;
        padding:0 2vw;
        border-radius:1vw;
      }

      .CloseBtn {
        width:100%;
        margin:1vw 0 0 0;

        .InfoImg {
          width:4vw;
          height:4vw;
          @media (min-width: 481px) and (max-width: 767px) {
            width:8vw;
            height:8vw;
          }
          @media (max-width: 481px) {
            width:12vw;
            height:12vw;
          }
        }
      }

      p {
        width:100%;
        text-indent: 2vw;
      }
    }
  }

  .LastSoftware {
    width:100%;
    margin:2vw 0 2vw 0;

    .LastSoftTitle {
      width:calc(95% - 2vw);
      margin:1vw;
      font-size:130%;
    }

    .LoadMore{
      width:95%;

      .LoadMoreBtn {
        width:20%;
        min-height:2vw;
        margin:0.5vw 0 0 0;
        background-color: #191;
        border-radius:0.5vw;
        @media (min-width: 481px) and (max-width: 767px) {
          width:30%;
          min-height:5vw;
          margin:1vw 0 0 0;
          border-radius:1vw;
        }
        @media (max-width: 481px) {
          width:40%;
          min-height:8vw;
          margin:1.5vw 0 0 0;
          border-radius:1vw;
        }
        &:hover {
          background-color: #171;
        }
      }
    }
  }

  .IcoColor {
    filter: invert(100%);
  }
  
  .Pattern {
    position:absolute;
    width:100%;
    min-height:100%;
    opacity:0.025;
    background-image: url('https://bzdrive.com/files/workshop/boardPattern.jpg');
    background-repeat: repeat;
    z-index:-1;
  }
}